import React ,{useState}from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../assets/Logo_.png'
import { Link, useNavigate } from 'react-router-dom';
const NavHead = () => {
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const toggleOffCanvas = () => {
      setShow((show) => !show);
    };

    return (
        <>
            {[false].map((expand) => (
                <Navbar key={expand} bg="white" expand={expand} className="mb-0 sticky-top">
                    <Container>
                        <Link to={'/'}><img className='img-fluid' src={logo} /></Link>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={toggleOffCanvas} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            show={show}
                            onHide={toggleOffCanvas}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                  <Link to={'/'}><img className='img-fluid w-75 h-50' src={logo} /></Link>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link onClick={() => { navigate("/");toggleOffCanvas();}}>Home</Nav.Link>
                                    <Nav.Link onClick={() => { navigate("/privacy");toggleOffCanvas();}}>Privacy Policy</Nav.Link>
                                    <Nav.Link onClick={() => { navigate("/terms");toggleOffCanvas();}}>Terms & Conditions</Nav.Link>
                                    <Nav.Link onClick={() => { navigate("/aboutus");toggleOffCanvas();}}>About Us</Nav.Link>
                                    <Nav.Link onClick={() => { navigate("/careers");toggleOffCanvas();}}>Careers</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
}

export default NavHead;