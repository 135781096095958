import React from 'react'
import Header from './Header';
import LandingPage from './LandingPage';

export default function CommonLandingPage() {
  return (
    <div>
          <Header />
          <LandingPage />
    </div>
  )
}
