import React, { useEffect, useState } from 'react'
import axios from 'axios';
const Privacypolicy = () => {
    const [policy, setPolicy] = useState("");
    const getPolicy = () => {
        let url = process.env.REACT_APP_BASEURL + `content/privacy-policy/`;
        axios.get(url).then((res) => {
            console.log("res", res.data);
            setPolicy(res.data)
        })
            .catch((err) => {
                console.log("error", err);
            })
    };
    useEffect(() => {
        getPolicy();
    }, []);
    return (
        <div>
            <div className='container'>
                <div className='row text-center'>
                    <h2>{policy.title ? policy.title : "N/A"}</h2>
                    <div className='col'>
                        <p>{policy.content ? policy.content : "N/A"}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacypolicy