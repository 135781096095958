import React from 'react'
import logo from "../assets/Abouts Logo.png";
import googleplay from "../assets/google-play-badge.png";
import appstore from "../assets/App Store Button.png";
import { FaInstagram } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import {
    TiSocialLinkedin,
    TiSocialFacebook,
    TiSocialTwitter,
} from "react-icons/ti";
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
    let navigate = useNavigate();

  return (
    <>
    <div>
          <div className="footerbg pt-5">
              <div className="container mb-0 ">
                  <div className="row justify-content-lg-between">
                      <div className="col-6 col-sm-4 col-lg-auto pt-5">
                        <Link to={'/'}>
                          <img
                              src={logo} alt=""
                              className="img-fluid"
                              style={{ width: "150px" }} 
                          />
                          </Link>
                          <h6 className="fw-normal text-white">
                             Aforro Groceries Pvt. Ltd.
                          </h6>
                      </div>
                      <div className="col-6 col-sm-4 col-lg-auto mb-3">
                          <h5 className="fw-bold mb-3 colbg">
                              Aforro
                          </h5>
                          <ul className="list-unstyled mb-md-4 mb-lg-0 text-white">
                              <li className="mb-2 arrow"  onClick={() => {navigate("/privacy")}}>
                                     Privacy Policy
                              </li>
                              <li className="mb-2 arrow"  onClick={() => {navigate("/terms")}}>
                                      Terms & Conditions
                              </li>
                              <li className="mb-2 arrow"  onClick={() => {navigate("/aboutus")}}>
                                      About Us
                              </li>
                              <li className="mb-2 arrow"  onClick={() => {navigate("/careers")}}>
                                      Careers
                              </li>
                          </ul>
                      </div>
                      <div className="col-6 col-sm-4 col-lg-auto mb-3">
                          <h5 className="fw-bold mb-3 colbg">
                              Follow
                          </h5>
                          <ul className="list-unstyled mb-md-4 mb-lg-0 text-white">
                              <li className="mb-2 arrow"><Link className='text-decoration-none text-white' to={"https://www.instagram.com/aforro_groceries/"} target="_blank">
                                <FaInstagram className="fs-4 border rounded-pill p-1 border-warning me-2" />Instagram</Link>
                              </li>
                              {/* <li className="mb-2 arrow">
                                 <BsYoutube className="fs-5 border rounded-pill p-1 border-warning me-2" />Youtube
                              </li> */}
                              <li className="mb-2 arrow"><Link className='text-decoration-none text-white' to={"https://www.facebook.com/aforrogroceries/"} target="_blank">
                                <TiSocialFacebook className="fs-4 border rounded-pill p-1 border-warning me-2" />Facebook</Link>
                              </li>
                              {/* <li className="mb-2 arrow">
                                <Link className='text-decoration-none text-white' to={""} target="_blank">
                                <TiSocialTwitter className="fs-4 border rounded-pill p-1 border-warning me-2" />Twitter
                                </Link>
                              </li> */}
                              <li className="mb-2 arrow"><Link className='text-decoration-none text-white' to={"https://www.linkedin.com/company/aforro-groceries-pvt-ltd/"} target="_blank">
                                 <TiSocialLinkedin className="fs-4 border rounded-pill p-1 border-warning me-2" />Linkedin</Link>
                              </li>
                          </ul>
                      </div>
                      <div className="col-6 col-sm-4 col-md-12 col-lg-auto mb-3">
                        <div className='row text-center'>
                            <div className='col mt-5 text-white'>
                                <div className='small fw-bold mb-2 fs-5'>Download The App Now.</div>
                                <div>
                                 <a href='https://play.google.com/store/apps/details?id=com.Aforro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank">
                                    <img alt='Get it on Google Play' src={googleplay} className='img-fluid m-1' width={160} height={110}/>
                                 </a>
                                 {/* <a href='' target="_blank"> */}
                                    <img alt='Get it on Google Play' src={appstore} className='img-fluid m-1' width={150} height={100}/>
                                 {/* </a> */}
                                </div>
                                <div className='small fw-bold text-end'><span>Coming soon...</span></div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>             
          </div>
           <div className='copyright'>
              <div className="container text-white py-4">
                  <div className="row">
                      <div className="col text-center">
                          <small>Copyright ©aforro Groceries 2023 All rights reserved</small>
                      </div>
                  </div>
              </div>
           </div> 
    </div>
      </>
  )
}

export default Footer