import './App.css';
import { useRoutes } from "react-router-dom";
import Themeroutes from './components/Router/Router';

function App() {
  const routing = useRoutes(Themeroutes)
  return (
    <div>
      {routing}
    </div>
  );
}

export default App;
