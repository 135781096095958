import React from 'react'
import aboutlogo from "../assets/Abouts Logo.png"
import works from "../assets/002.png"
import works2 from "../assets/003.png"
import works3 from "../assets/004.png"
import works4 from "../assets/005.png"
import google from '../assets/google-play-badge.png'
import appstore from '../assets/App Store Button.png'
const LandingPage = () => {
    return (
        <>
        <div className='container-fluid'>
           <div className='container py-4'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 text-center pt-4 mt-5'>
                    <img className='img-fluid' src={aboutlogo}/>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 text-start mt-5'>
                    <h3>About us</h3>
                    <p className='about'>Why should big cities have all the ease? Why can’t tier 2 cities get their groceries delivered at doorstep within minutes? Why only big cities are targeted by service providers when it comes to tech and comfort? Aforro has answer to them all, and Aforro stands right beside tier 2 cities, working continuously to bring ease and comfort of ordering groceries online..</p>
                </div>
            </div>
           </div>
           <div className='container-fluid bgcol'>
            <h3 className='text-center py-5 fw-semibold'>How it works ?</h3>
            <div className='row text-center'>
                <div className='col-lg-6 col-md-6 col-sm-12 text-end pb-5'>
                    <img src={works} className='img-fluid'/>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 text-start d-flex justify-content-center align-items-center pb-5'>
                    <div className='pe-5 me-5'>
                    <h3 className='colbg fw-bold'>Download The App From <br/>The Given Link</h3>
                    {/* <h6 className='text-secondary mb-1'>Why should big cities have all the ease?</h6> */}
                    {/* <h6 className='text-secondary'>Why can't tier 2 cities get their groceries.</h6> */}
                     <div className='row text-center'>
                        <div className='col-12 mt-4'>
                          <div>
                            <a href='https://play.google.com/store/apps/details?id=com.Aforro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank">
                              <img alt='Get it on Google Play' src={google} className='img-fluid m-1' width={160} height={110}/>
                            </a>
                            {/* <a href='' target="_blank"> */}
                            <img src={appstore} className='img-fluid m-1' width={150} height={100} />
                            {/* </a> */}
                          </div>
                          <div className='small fw-bold text-end'><span className=''>Coming soon...</span></div>
                        </div>
                     </div>
                     </div>
                </div>
            </div>
           </div>
           <div className='container-fluid'>
            <div className='row text-center'>               
                <div className='col-lg-6 col-md-6 col-sm-12 text-start d-flex justify-content-center align-items-center py-5'>
                    <div>
                     <h3 className='colbg fw-bold'>Add to cart everything that's <br />in your mind</h3>
                    {/* <h6 className='text-secondary mb-1'>Why should big cities have all the ease?</h6> */}
                    {/* <h6 className='text-secondary'>Why can't tier 2 cities get their groceries.</h6> */}
                     </div>
                </div>
                 <div className='col-lg-6 col-md-6 col-sm-12 text-start py-5'>
                    <img src={works2} className='img-fluid'/>
                </div>
            </div>
           </div>
           <div className='container-fluid bgcol'>
            <div className='row text-center'>
                <div className='col-lg-6 col-md-6 col-sm-12 py-5 text-end'>
                    <img src={works3} className='img-fluid'/>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 text-start d-flex justify-content-center align-items-center py-5'>
                    <div>
                     <h3 className='colbg fw-bold'>Place an order and relax <br />while we work on fulfilling  <br />the order</h3>
                    {/* <h6 className='text-secondary mb-1'>Why should big cities have all the ease?</h6> */}
                    {/* <h6 className='text-secondary'>Why can't tier 2 cities get their groceries.</h6> */}
                     </div>
                </div>
            </div>
           </div>
            <div className='container-fluid'>
            <div className='row text-center'>               
                <div className='col-lg-6 col-md-6 col-sm-12 text-start d-flex justify-content-center align-items-center py-5'>
                    <div>
                    <h3 className='colbg fw-bold'>Your order will be delivered <br />within 30-60 minutes </h3>
                    {/* <h6 className='text-secondary mb-1'>Why should big cities have all the ease?</h6> */}
                    {/* <h6 className='text-secondary'>Why can't tier 2 cities get their groceries.</h6> */}
                     </div>
                </div>
                 <div className='col-lg-6 col-md-6 col-sm-12 text-start py-5'>
                    <img src={works4} className='img-fluid'/>
                </div>
            </div>
           </div>
        </div>
        </>
)}

export default LandingPage