import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import NavHead from "../NavHead";

const FullLayout = () => {
    return (
        <>
            <NavHead />
            <Outlet />
            <Footer />
        </>
    )
}
export default FullLayout